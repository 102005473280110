"use client";

import { Menu, Transition } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { signOut, useSession } from "next-auth/react";
import { Fragment } from "react";

export const UserSessionBadge = () => {
  const { data: session, status } = useSession();

  return (
    <>
      <button
        type="button"
        aria-label="Regístrese o inicie sesión"
        className="-ml-1 -mr-2 p-2 h-max w-max text-sm text-gray-700 font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
      >
        <div className="m-1 sm:ml-6">
          {status !== "loading" ? (
            <>
              {session ? (
                <Menu as="div" className="relative">
                  <Menu.Button>
                    <span className="sr-only">Menú usuario</span>
                    <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-gray-500">
                      <span className="text-xs font-medium leading-none text-white">
                        {session.user?.name?.charAt(0) ||
                          session.user?.email?.charAt(0)}
                      </span>
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 w-32 origin-top-right rounded-md bg-white pt-0.5 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        <a
                          href="/mis-listas"
                          className={
                            "w-full hover:bg-primary hover:text-white bg-gray-50 block text-sm leading-4 text-start px-3 py-3 mb-1 text-gray-900 rounded-md bg-white shadow-sm focus:outline-none focus:ring-1 focus:ring-secondary"
                          }
                        >
                          Colección y lista de deseos
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a
                          href="/panel-vendedor"
                          className={
                            "w-full hover:bg-primary hover:text-white bg-gray-50 block text-sm leading-4 text-start px-3 py-3 mb-1 text-gray-900 rounded-md bg-white shadow-sm focus:outline-none focus:ring-1 focus:ring-secondary"
                          }
                        >
                          Herramientas para vendedores
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <a
                          href="/account"
                          className={
                            "w-full hover:bg-primary hover:text-white bg-gray-50 block text-sm leading-4 text-start px-3 py-3 mb-1 text-gray-900 rounded-md bg-white shadow-sm focus:outline-none focus:ring-1 focus:ring-secondary"
                          }
                        >
                          Ajustes
                        </a>
                      </Menu.Item>
                      <Menu.Item>
                        <button
                          onClick={() => {
                            signOut();
                          }}
                          className={
                            "w-full hover:bg-primary hover:text-white bg-gray-50 block text-sm leading-4 text-start px-3 py-3 mb-1 text-gray-900 rounded-md bg-white shadow-sm focus:outline-none focus:ring-1 focus:ring-secondary"
                          }
                        >
                          Cerrar sesión
                        </button>
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : (
                <a href="/login">
                  <span className="inline-block h-6 w-6 overflow-hidden rounded-full bg-gray-100">
                    <svg
                      className="h-full w-full text-gray-300"
                      fill="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                    </svg>
                  </span>
                </a>
              )}
            </>
          ) : (
            // hidden
            <span className="inline-block h-6 w-6 overflow-hidden rounded-full bg-gray-100">
              <svg
                className="h-full w-full text-gray-300 hidden"
                fill="currentColor"
                viewBox="0 0 24 24"
              >
                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
              </svg>
            </span>
          )}
        </div>
      </button>
    </>
    // <div className="mx-5 sm:ml-6 flex items-center text-xs lg:text-sm font-medium text-gray-700 hover:text-gray-800">
    //
    // </div>
  );
};
