"use client";

import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import { getCookie, setCookie } from "cookies-next";

const CookieConsentBanner = () => {
  const cookie = getCookie("cookie_consent") || false;
  const [open, setOpen] = useState(cookie === "true" ? false : true);

  const handleAccept = () => {
    setOpen(false);
    setCookie("cookie_consent", "true", { maxAge: 60 * 60 * 24 * 90 });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 transition-opacity" />
        </Transition.Child>

        <div className="fixed bottom-0 z-10 w-screen overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left border-gray-600 border-1 transition-all">
              <div>
                <div className="text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-base font-semibold leading-6 text-gray-900"
                  >
                    Aviso de cookies
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-xs text-gray-500">
                      Este sitio web no utiliza cookies de terceros, con fines
                      de analítica o publicidad. Utilizamos cookies propias
                      necesarias para que el sitio web funcione. Usualmente
                      están configuradas para responder a acciones hechas por tí
                      tales como iniciar sesión en el sitio, o llenar
                      formularios. Puedes configurar tu navegador para bloquear
                      o alertar la presencia de estas cookies, pero algunas
                      partes del sitio web no funcionarán. Estas cookies no
                      guardan ninguna información personal identificable. <br />
                      <br /> Para más información, visita nuestra{" "}
                      <a
                        href="/politica-de-privacidad"
                        tabIndex={-1}
                        className="underline"
                      >
                        política de privacidad
                      </a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-center mt-4">
                <button
                  type="button"
                  onClick={() => handleAccept()}
                  className="flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Aceptar
                </button>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CookieConsentBanner;
