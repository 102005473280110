"use client";
import { getCookie } from "cookies-next";
import Script from "next/script";

const VercelAnalyticsWrapper = () => {
  const cookie = getCookie("is_admin") || false;
  return (
    <>
      {!cookie ? (
        <>
          <Script
            async
            src="https://umami.vendermonedas.es/script.js"
            data-website-id="06ef53d7-ccfc-4461-951f-9e8c1a2619c0"
          ></Script>
        </>
      ) : (
        <p className="flex h-14 lg:h-10 py-2 lg:py-1 items-center justify-center bg-[#ef4444] px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
          <span>Analytics are disabled by admin cookie</span>
        </p>
      )}
    </>
  );
};

export default VercelAnalyticsWrapper;
