import { Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { useRouter } from "next/navigation";

const SearchClientComponent = () => {
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);

  const doSearch = (f: any) => {
    window.location.href = `/buscar/${f.search}`;
  };

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  return (
    <>
      {isOpen ? (
        <div className="z-40 fixed inset-0 bg-slate-900/25 backdrop-blur transition-opacity opacity-100"></div>
      ) : null}
      <div>
        <button
          type="button"
          aria-label="Buscar"
          onClick={openModal}
          className="-ml-2 p-2 h-max w-max text-sm text-gray-700 font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
          <MagnifyingGlassIcon
            className="m-1 h-5 w-5 inline"
            aria-hidden="true"
          />
        </button>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="fixed inset-0 z-50 flex items-start justify-center pt-16 sm:pt-24">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="relative w-full max-w-lg transform px-4 transition-all opacity-100 scale-100">
                  <div className="overflow-hidden rounded-lg bg-white shadow-md">
                    <div className="flex rounded-md shadow-sm">
                      <form
                        onSubmit={handleSubmit(doSearch)}
                        className="relative flex flex-grow items-stretch focus-within:z-10"
                      >
                        <input
                          {...register("search", { required: true })}
                          type="text"
                          className="block w-full appearance-none bg-transparent py-4 pl-4 pr-12 text-base text-slate-900 placeholder:text-slate-600 focus:outline-none sm:text-sm sm:leading-6"
                          placeholder="Prueba a introducir un año para ver los resultados"
                        />
                        <button
                          type="submit"
                          className="inline-flex items-center rounded border border-gray-200 px-5 font-sans text-md text-gray-400 select-none cursor-pointer"
                        >
                          Enter
                        </button>
                      </form>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
export default SearchClientComponent;
