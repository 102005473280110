"use client";

import { Fragment, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import BurgerMenuMobile from "./header/BurgerMenuMobile";
import SearchClientComponent from "./header/SearchClientComponent";
import FlagIcon from "./FlagIcon";
import { UserSessionBadge } from "./header/UserSessionBadge";
import { Bars3Icon } from "@heroicons/react/24/outline";

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

const Header = (props: { navigation: any[] }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="bg-white">
      <BurgerMenuMobile
        open={open}
        setOpen={setOpen}
        navigation={props.navigation}
      />

      <header className="relative">
        <nav aria-label="Top">
          {/* Primary navigation */}
          <div className="bg-white">
            <div className="mx-auto max-w-7xl px-3 sm:px-6 lg:px-8">
              <div className="border-b border-gray-200">
                <div className="flex h-16 items-center justify-between">
                  {/* Logo (lg+) */}
                  <div className="hidden lg:flex lg:items-center">
                    <a href="/">
                      <span className="sr-only">Vendermonedas.es</span>
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img
                        className="h-8 w-auto mr-2 min-w-full"
                        width={1536}
                        height={813}
                        src="/logo.svg"
                      />
                    </a>
                    <div className="hidden h-full lg:flex">
                      {/* Mega menus */}
                      <Popover.Group className="ml-8">
                        <div className="flex h-full justify-center space-x-8">
                          {props.navigation.map((category, categoryIdx) => (
                            <Popover key={category.name} className="flex">
                              {({ open }) => (
                                <>
                                  <div className="relative flex">
                                    <Popover.Button
                                      className={classNames(
                                        open
                                          ? "border-primary text-primary"
                                          : "border-transparent text-gray-900 hover:text-primary",
                                        "relative z-10 -mb-px flex items-center border-b-2 pt-px text-sm font-medium transition-colors duration-200 ease-out"
                                      )}
                                    >
                                      {category.name} por país
                                    </Popover.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Popover.Panel className="absolute inset-x-0 top-full text-gray-500 sm:text-sm z-50">
                                      {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                                      <div
                                        className="absolute inset-0 top-1/2 bg-white shadow"
                                        aria-hidden="true"
                                      />

                                      <div className="relative bg-white">
                                        <div className="mx-auto max-w-7xl px-8">
                                          <div className="grid grid-cols-2 items-start gap-y-10 gap-x-8 pt-10 pb-12">
                                            <div className="grid grid-cols-2 gap-y-10 gap-x-8">
                                              <div>
                                                <a
                                                  className="rounded-md bg-white px-3.5 py-2.5 mx-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 lg:text-xs xl:text-sm"
                                                  href={`/${category.cta.href}`}
                                                >
                                                  {category.cta.text}
                                                </a>
                                                <ul
                                                  role="list"
                                                  aria-labelledby={`desktop-featured-heading-${categoryIdx}`}
                                                  className="mt-6 space-y-6 sm:space-y-4"
                                                >
                                                  {category.countries.map(
                                                    (item: any) => (
                                                      <li
                                                        key={item.name}
                                                        className="flex"
                                                      >
                                                        <a
                                                          href={`/${item.href}`}
                                                          className="flex font-semibold leading-5 text-gray-900 hover:text-primary text-center mt-2"
                                                        >
                                                          <span className="flex items-center">
                                                            <div className="px-2 w-[2rem]">
                                                              <FlagIcon
                                                                country={
                                                                  item.name
                                                                }
                                                              />
                                                            </div>
                                                          </span>
                                                          <span className="flex">
                                                            {item.name}
                                                          </span>
                                                        </a>
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Popover.Panel>
                                  </Transition>
                                </>
                              )}
                            </Popover>
                          ))}
                        </div>
                      </Popover.Group>
                    </div>
                  </div>

                  {/* Mobile menu and search (lg-) */}
                  <div className="flex items-center lg:hidden">
                    <button
                      type="button"
                      className="-ml-2 rounded-md bg-white p-2 text-gray-400"
                      onClick={() => setOpen(true)}
                    >
                      <span className="sr-only">Open menu</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>

                  {/* Logo (lg-) */}
                  <a href="/" className="lg:hidden">
                    <span className="sr-only">Vendermonedas.es</span>
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                      width={1536}
                      height={813}
                      src="/logo.svg"
                      className="h-8 pr-2.5 w-auto"
                    />
                  </a>

                  <div className="flex items-center justify-end">
                    <div className="flex items-center lg:ml-8">
                      <SearchClientComponent />
                      <span
                        className="mx-1 h-6 w-px bg-gray-200 lg:mx-6"
                        aria-hidden="true"
                      />

                      <div className="flex items-center justify-end">
                        <UserSessionBadge />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
      {/* <p className="flex h-14 lg:h-10 py-2 lg:py-1 items-center justify-center bg-secondary px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
        <span role="img" aria-label="check">
          🏗️
        </span>
        <span>Estamos en fase beta - ¡Haz click aquí para registrarte!</span>
      </p> */}
    </div>
  );
};

export default Header;
