import Image from "next/image";
const FlagIcon = (props: { country: any }) => {
  switch (props.country) {
    case "España":
      return (
        <Image
          src="/flag_icons/spain.png"
          width={64}
          height={43}
          alt={"España"}
        />
      );
    case "Portugal":
      return (
        <Image
          src="/flag_icons/portugal.png"
          width={64}
          height={43}
          alt={"Portugal"}
        />
      );

    default:
      return <></>;
  }
};

export default FlagIcon;
